(function ($) {
    $(function () {

        $('a[href^="#"]').click(function () {
            var speed = 500;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top;
            $("html, body").animate({scrollTop: position}, speed, "swing");
            return false;
        });

        var topBtn = $('.l-footer_pagetop');
        topBtn.hide();
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                topBtn.fadeIn();
            } else {
                topBtn.fadeOut();
            }
        });



        $('.l-header_sp-menu-btn').attr('aria-pressed','false');


        $('.l-header_sp-menu-btn').on('click', function () {
            // $('#denwa-cont-nav').slideToggle();
            $(this).toggleClass('is-open');
            $(this).toggleAttrVal('aria-pressed', "true", "false");
            $(this).find('.toggle-text').toggleTxt('メニュー','閉じる');
            $('.l-header_search,.l-header_secondary,.l-header_primary-btns').slideToggle();
        });


        $('.l-footer_sideclose').on('click', function () {
            $('.l-footer_sidebtn').hide();
        });


        $('.e-page-header').wrapInner('<span class="main" />');

        $('dl.c-faq-dl dt').on('click',function () {
            $(this).next().slideToggle();
            $(this).toggleClass('is-open');
        });


        $('#gas-modelcase .nav span').on('click', function (e) {
            $('#gas-modelcase .nav span').removeClass('current');
            $(this).addClass('current');
            let currentContent = $(this).data('href');
            $('.tabcontents div').hide();
            console.log(currentContent);
            if (currentContent.length) {
                $(currentContent).show();
            }
        });


    });


    $.fn.toggleAttrVal = function (attr, val1, val2) {
        var test = $(this).attr(attr);
        if (test === val1) {
            $(this).attr(attr, val2);
            return this;
        }
        if (test === val2) {
            $(this).attr(attr, val1);
            return this;
        }
        // default to val1 if neither
        $(this).attr(attr, val1);
        return this;
    };

    $.fn.toggleTxt = function (val1, val2) {
        var testTxt = $(this).text();
        if (testTxt === val1) {
            $(this).text(val2);
            return this;
        }
        if (testTxt === val2) {
            $(this).text(val1);
            return this;
        }
        // default to val1 if neither
        $(this).text(val1);
        return this;
    };
})(jQuery);






